/*
 * Angular 2 decorators and services
 */
import { LayoutService, LoaderIconRegistryService, ModalManagementService, ToastService, WINDOW } from '@activia/ngx-components';
import { ApiTokenService, AuthFacade, CMRole } from '@amp/auth';
import { GlobalFacade } from '@amp/global';
import { NewFeatureService } from '@amp/global';
import { MessengerNotificationService } from '@amp/messenger';
import { isFQDN } from '@amp/utils/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { merge, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { RouterFacade } from '@amp/router-store';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'cm-root',
  encapsulation: ViewEncapsulation.None,
  template: ` <router-outlet></router-outlet> `,
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Pattern used to close all subscriptions
   *
   * @ignore
   */
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private titleService: Title,
    @Inject(WINDOW) public window: Window,
    private newFeatureService: NewFeatureService,
    private translate: TranslocoService,
    private authFacade: AuthFacade,
    private globalFacade: GlobalFacade,
    private messengerNotificationService: MessengerNotificationService,
    loaderIconRegistryService: LoaderIconRegistryService,
    private layoutService: LayoutService,
    private routerFacade: RouterFacade,
    private modalManagementService: ModalManagementService,
    private toastService: ToastService,
    private apiTokenService: ApiTokenService,
  ) {
    // Init the translate settings
    loaderIconRegistryService.loadIconFromAsset('./assets/img/amplogo-78_78.png');

    // show the new feature tour (once user prefs are loaded)
    this.globalFacade.newFeaturePreferencesLoaded$.pipe(takeUntil(this.componentDestroyed$)).subscribe((preferences) => {
      this.newFeatureService.openNewFeatureTour(preferences, true);
    });
  }

  /**
   * @ignore
   */
  public ngOnInit() {
    /**
     * This listens to state changes in the router to set the page title.
     */
    this.routerFacade.routerNavigated$.pipe(takeUntil(this.componentDestroyed$)).subscribe((navigatedRoute) => {
      // update the active route of the layout
      this.layoutService.setActiveRoute(decodeURIComponent(navigatedRoute.payload.routerState.url));

      // update the page title
      const routeTitle = navigatedRoute.payload.routerState.data['title'];
      this.translate
        .selectTranslate(routeTitle || 'app.name')
        .pipe(take(1))
        .subscribe((title) => {
          this.titleService.setTitle(title);
        });
    });

    // init auth
    this.initAuth();

    if (!isFQDN(this.window.location.hostname)) {
      this.messengerNotificationService.showInfoMessage('global.error.not-fqdn');
    }
  }

  /**
   * Inits the authentication process.
   * Handle redirects of the user after a successful login (manual or from previous valid token in storage)
   */
  private initAuth() {
    // on a successful manual login, redirect the user to the relevant page based on his role
    const manualLoginCompleted$ = this.authFacade.onLoginInitiated$.pipe(switchMap(() => this.authFacade.onLoginCompleted$.pipe(take(1))));

    // on a app session ready / initial load (token from local storage still valid), also redirect the user to the relevant page if the requested page is the login page
    const appSessionReadyNeedsRedirect$ = this.authFacade.onLoginCompleted$.pipe(
      withLatestFrom(this.routerFacade.currentRoute$),
      take(1),
      filter(([, currentUrl]) => currentUrl?.url === '/login'),
      map(([authenticatedUser]) => authenticatedUser),
      takeUntil(this.authFacade.onLoginInitiated$)
    );

    merge(appSessionReadyNeedsRedirect$, manualLoginCompleted$)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((authenticatedUser) => {
        // Redirections based on user role
        const redirects = {
          [CMRole.ROLE_HOTLINE]: 'monitoring',
          default: 'dashboard',
        };
        const redirectUrl = redirects[authenticatedUser.userRoles.find((userRole) => !!redirects[userRole.name])?.name || 'default'];
        this.routerFacade.navigate({ path: ['app', redirectUrl] });
      });

    // redirect user to login page when unsuccessful
    merge(this.authFacade.onLogoutFailed$, this.authFacade.onLogoutSuccess$)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        // close any opened modal
        this.modalManagementService.closeAllOpenedModals();

        // clears the toast queue
        this.toastService.clearToastQueue();

        this.routerFacade.navigate({ path: ['login'] });
      });

      // retreive the session if passed and initialize it in the token service.
      // added for sso scenarios
      if ( this.window.location.href.includes('?') ) {
        let [hash, query] = ['', ''];
        if ( this.window.location.href.includes('#')) {
          [hash , query] = this.window.location.href.split('#')[1].split('?');
        }
        else {
          hash = '';
          query = this.window.location.href.split('?')[1];
        }
        const sParams = new URLSearchParams(query);

        const session = sParams.get('sessionid');
        if (session ) {
          this.apiTokenService.setApiToken(session);
        }
        this.window.history.pushState({}, '', hash);
      }


    // Inits the application trying to login the user with an existing token
    this.authFacade.initAuth();
  }

  /**
   * @ignore
   */
  public ngOnDestroy(): void {
    // unsubscribe all subscriptions
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
