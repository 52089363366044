import { ActivatedRouteSnapshot, Data, Params, Router, RouterStateSnapshot } from '@angular/router';

import { IRouterStateUrl } from './router.interfaces';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

const getRouteParams = (route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params => {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;
  return { ...currentParams, ...getRouteParams(primaryChild, getter) };
};

const getRouteData = (route: ActivatedRouteSnapshot): Data => {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild;
  return { ...currentData, ...getRouteData(primaryChild) };
};

/**
 * Custom serializer to specify how/what data to save in the router state
 */
@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<IRouterStateUrl> {
  constructor(private _router: Router) {}

  serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
    return {
      url: routerState.url,
      params: getRouteParams(routerState.root, (r) => r.params),
      queryParams: getRouteParams(routerState.root, (r) => r.queryParams),
      data: getRouteData(routerState.root),
      extras: this._router.getCurrentNavigation()?.extras || {},
    };
  }
}
