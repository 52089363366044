import { GlobalFacade } from '@amp/global';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';
import { SessionStorageService } from 'ngx-webstorage';
import { filter } from 'rxjs/operators';

export const localeProviderFactory = (sessionStorageService: SessionStorageService, transloco: TranslocoService, globalFacade: GlobalFacade): (() => Promise<any>) => () => {
    /** the supported languages of the application */
    const SUPPORTED_LANGUAGES = [
      { id: 'en', label: 'English' },
      { id: 'fr', label: 'Français' },
      { id: 'es', label: 'Español' },
    ];
    /** default language to use */
    const DEFAULT_LANGUAGE = 'en';
    let startLang = DEFAULT_LANGUAGE;

    const sessionStorageLang = sessionStorageService.retrieve('lang');
    if ((sessionStorageLang != null && SUPPORTED_LANGUAGES.map((l) => l.id).includes(sessionStorageLang)) || sessionStorageLang === 'de') {
      startLang = sessionStorageLang;
    } else {
      const browserLang = getBrowserLang();
      if ((browserLang != null && SUPPORTED_LANGUAGES.map((l) => l.id).includes(browserLang)) || browserLang === 'de') {
        startLang = browserLang;
      }
    }
    transloco.setActiveLang(startLang);
    // set the supported languages
    transloco.setAvailableLangs(SUPPORTED_LANGUAGES);
    // When the language changes, we need to store it in session storage
    transloco.langChanges$.subscribe((lang: string) => {
      sessionStorageService.store('lang', lang);
    });
    // everytime the user language changes, make sure it is a supported language
    globalFacade.userLanguage$.pipe(filter((userLang) => !!userLang)).subscribe((lang) => {
      let updatedLang: string;
      if (!SUPPORTED_LANGUAGES.map((l) => l.id).includes(lang)) {
        updatedLang = startLang;
      } else {
        updatedLang = lang;
      }
      // do nothing if the lang hasn't changed
      if (transloco.getActiveLang() !== updatedLang) {
        transloco.setActiveLang(updatedLang);
        // pre load all scope files also
        Promise.all(i18nFiles(updatedLang).map((l) => transloco.load(l).toPromise()));
      }
    });
    // pre load all scope files also
    return Promise.all(i18nFiles(startLang).map((l) => transloco.load(l).toPromise()));
  };

const i18nFiles = (lang: string): string[] => [
    lang,
    `device-fields/${lang}`,
    `dashboard-scope/${lang}`,
    `monitoring-scope/${lang}`,
    `site-monitoring-scope/${lang}`,
    `site-monitoring-shared-scope/${lang}`,
    `site-management-scope/${lang}`,
    `notification-scope/${lang}`,
    `user-settings-scope/${lang}`,
    `tags-scope/${lang}`,
    `alarm-events/${lang}`,
  ];
