import { UserAuthorizationScopesService } from '@activia/cm-api';
import { AuthFacade } from '@amp/auth';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { INewFeaturePreferences } from '../new-feature/new-feature-preferences.interface';

import * as GlobalAction from './global.action';
import { IGlobalState } from './global.reducer';
import { globalQuery } from './global.selectors';

@Injectable({ providedIn: 'root' })
export class GlobalFacade {
  /** Connection status */
  // Whether if user is connected to the network
  isUserOnline$ = this.store.pipe(select(globalQuery.getIsUserOnline));

  /** store observables */
  userLanguage$ = this.store.pipe(select(globalQuery.getUserLanguage));
  newFeaturePreferences$ = this.store.pipe(select(globalQuery.getNewFeaturePreferences));
  newFeaturePreferencesLoaded$ = this.newFeaturePreferences$.pipe(filter((prefs) => prefs.preferencesLoaded));
  defaultTimeZone$ = this.store.pipe(select(globalQuery.getDefaultTimeZone));
  /** List of device groups available to the role of the current user */
  deviceGroups$ = this.store.pipe(select(globalQuery.getDeviceGroups));
  searchResultSize$ = this.store.pipe(select(globalQuery.getSearchResultSize));
  engineVersion$ = this.store.pipe(select(globalQuery.getEngineVersion));
  customerName$ = this.store.pipe(select(globalQuery.getCustomerName));

  /** Default device group at app level (used in dashboard, user settings, side nav) */
  defaultDeviceGroup$ = combineLatest([this.store.pipe(select(globalQuery.getDefaultDeviceGroup)), this.authFacade.authenticatedUserScopes$]).pipe(
    map(([defaultDeviceGroup, scopes]) => {
      if (!defaultDeviceGroup) {
        if (scopes && scopes.length > 0) {
          const authorizeAllDeviceGroups = scopes.filter((scope) => scope.authorizeAllDeviceGroups === true).length > 0;
          if (authorizeAllDeviceGroups) {
            defaultDeviceGroup = { id: 1, name: 'All Devices' };
          } else {
            this.scopesService.getDgForScope(scopes[0].id).subscribe((groups) => {
              defaultDeviceGroup = groups[0];
            });
          }
        }
      }
      return defaultDeviceGroup;
    })
  );

  // Tour
  public tourElementSettings$ = this.store.pipe(select(globalQuery.getTourElement));
  public isTourElementLoaded$ = this.store.pipe(select(globalQuery.isTourSettingsLoaded));

  constructor(private store: Store<IGlobalState>, private authFacade: AuthFacade, private scopesService: UserAuthorizationScopesService) {}

  /** Updates the user language */
  public updateUserLanguage(lang: string) {
    this.store.dispatch(GlobalAction.UserLanguageUpdate({ language: lang }));
  }

  /** Updates the new feature dialog settings */
  public updateNewFeatureSettings(settings: INewFeaturePreferences) {
    this.store.dispatch(GlobalAction.NewFeatureSettingsUpdate({ preference: settings }));
  }

  /** Update user preferences back into store */
  public updateUserPreferences(preferences: Array<{ key: string; value: any; field: string }>) {
    this.store.dispatch(GlobalAction.UserPreferencesUpdate({ preferences }));
  }

  /** Tour Id */
  public tourElementUpdate(tourId: string, lastViewElement: string, neverShowAgain: boolean) {
    this.tourElementSettings$.pipe(take(1)).subscribe((tourSettings) => {
      const tourAlreadyViews = Array.from(new Set([...tourSettings.data.tourAlreadyView, lastViewElement]));
      this.store.dispatch(GlobalAction.TourElementUpdate({ tourId, lastViewElement, neverShowAgain, tourAlreadyView: tourAlreadyViews || [] }));
    });
  }
}
