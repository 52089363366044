import { DATE_TIME_OPTIONS } from '@activia/ngx-components';
import { GlobalFacade } from '@amp/global';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { Translation, TranslocoLoader, TranslocoModule, TranslocoService, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SessionStorageService } from 'ngx-webstorage';
import { localeProviderFactory } from './locale-initializer';

/**
 * The i18n configuration containing loaders and logic to load the locale into amp-site-monitor
 */
@Injectable({ providedIn: 'root' })
export class I18nLoaderService implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(langPath: string) {
    return this.http.get<Translation>(`/cm/assets/i18n/${langPath}.json`);
  }
}

export const I18N_PROVIDERS = [
  TranslocoService,
  { provide: TRANSLOCO_LOADER, useClass: I18nLoaderService },
  {
    // we need make sure ourselves the translation files are already loaded when using them, so
    // load some common used (cross modules) files to scope here.
    provide: TRANSLOCO_SCOPE,
    useValue: 'device-fields',
    multi: true,
  },
  {
    provide: TRANSLOCO_CONFIG,
    useValue: {
      availableLangs: ['en', 'fr', 'es'],
      fallbackLang: ['en'],
      defaultLang: 'en',
      reRenderOnLangChange: true,
      missingHandler: {
        allowEmpty: true,
        useFallbackTranslation: true,
      },
    },
  },
  {
    provide: DATE_TIME_OPTIONS,
    useFactory: (translate: TranslocoService) => ({ localeObservable: translate.langChanges$, showTimeKeypad: true, timezone: 'local' }),
    deps: [TranslocoService],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: localeProviderFactory,
    multi: true,
    deps: [SessionStorageService, TranslocoService, GlobalFacade],
  },
];

export const I18N_MODULES = [TranslocoModule];

// List of supported languages
export const SUPPORTED_LANGUAGES = [
  { id: 'en', label: 'English' },
  { id: 'fr', label: 'Français' },
  { id: 'es', label: 'Español' },
];

// define if the language in param is supported
export const isSupportedLanguage: (lang: string) => boolean = (lang: string) => SUPPORTED_LANGUAGES.map((l) => l.id).includes(lang);
