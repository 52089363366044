/* eslint-disable @typescript-eslint/prefer-for-of */
import { RoleDTO, UserDTO } from '@activia/cm-api';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map, mapTo } from 'rxjs/operators';

import { IAuthState } from './auth.reducer';
import { authQuery } from './auth.selectors';
import * as AuthAction from './auth.action';
import { Actions, ofType } from '@ngrx/effects';
import { CMRole } from '../roles.enum';
import { Observable } from 'rxjs';
import { PERMISSIONS } from '../permissions.constant';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  /** store observables */
  pendingAuthentication$ = this.store.pipe(select(authQuery.selectLoginRequestPending));
  authenticatedUser$ = this.store.pipe(select(authQuery.selectUserInfo));
  authenticatedUserRoles$ = this.store.pipe(select(authQuery.selectRoles));
  authenticatedUserScopes$ = this.store.pipe(select(authQuery.selectScopes));
  authenticatedUserPermissions$ = this.store.pipe(select(authQuery.selectPermissions));
  userApiToken$ = this.store.pipe(select(authQuery.selectUserToken));
  authStateReady$ = this.pendingAuthentication$.pipe(
    filter((pending) => pending === false),
    mapTo(true)
  );

  /** actions listeners */
  onAuthInitiated$ = this.actions.pipe(ofType(AuthAction.AuthInitiated));
  onLoginInitiated$ = this.actions.pipe(ofType(AuthAction.LoginInitiated));
  onLogoutSuccess$ = this.actions.pipe(ofType(AuthAction.LogoutSuccess));
  onLogoutFailed$ = this.actions.pipe(ofType(AuthAction.LogoutFail));
  onLoginCompleted$ = this.actions.pipe(ofType(AuthAction.LoginComplete));

  constructor(private store: Store<IAuthState>, private actions: Actions) {}

  /** logs in the user using the specified username and password */
  public login(username: string, password: string) {
    this.store.dispatch(
      AuthAction.LoginInitiated({
        username,
        password,
      })
    );
  }

  /** logs out the current user */
  public logoutCurrentUser() {
    this.store.dispatch(AuthAction.Logout());
  }

  /**
   * Inits the application trying to login the user with an existing token
   * **/
  public initAuth() {
    this.store.dispatch(AuthAction.AuthInitiated());
  }

  /** expire the current authenticated user's session */
  public expireCurrentSession(httpStatusCode: string) {
    this.store.dispatch(AuthAction.AuthExpired({ httpStatusCode }));
  }

  /** Update user information in auth store**/
  public updateUserInfo(user: UserDTO) {
    this.store.dispatch(AuthAction.UserInfoUpdate({ user }));
  }

  hasRole$(role: CMRole): Observable<boolean> {
    return this.authenticatedUserRoles$.pipe(map((roles) => roles?.map((r) => r.name).includes(<string>role)));
  }

  /** Returns an observable checking the authenticated user has any of the provided roles **/
  hasAnyRole$(...roles: CMRole[]) {
    return this.authenticatedUserRoles$.pipe(
      filter((urols) => !!urols),
      map((userRoles: RoleDTO[]) => userRoles.map((r) => r.name)),
      map((userRoles: string[]) => {
        for (let i = 0; i < roles.length; i++) {
          if (userRoles.includes(roles[i])) {
            return true;
          }
        }
        return false;
      })
    );
  }

  /** Returns an observable checking the authenticated user has any of the provided permission */
  hasAnyPermission$(...permissions: PERMISSIONS[]) {
    return this.authenticatedUserPermissions$.pipe(
      filter((perms) => !!perms),
      map((perms: string[]) => {
        for (let i = 0; i < permissions.length; i++) {
          if (perms.includes(permissions[i])) {
            return true;
          }
        }
        return false;
      })
    );
  }
}
