import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IGlobalState } from './global.reducer';
import { LoadingState } from '@activia/ngx-components';

const globalSelector = createFeatureSelector<IGlobalState>('global');

export const getIsUserOnline = createSelector(globalSelector, (state) => state.isUserOnline);

export const getUserLanguage = createSelector(globalSelector, (state) => state.lang);
export const getNewFeaturePreferences = createSelector(globalSelector, (state) => state.newFeaturePreferences);
export const getDefaultTimeZone = createSelector(globalSelector, (state) => state.defaultTimeZone);
export const getDeviceGroups = createSelector(globalSelector, (state) => state.deviceGroups);
export const getDefaultDeviceGroup = createSelector(globalSelector, (state) => state.defaultDeviceGroup);
export const getSearchResultSize = createSelector(globalSelector, (state) => state.searchResultSize);
export const getEngineVersion = createSelector(globalSelector, (state) => state.engineVersion);
export const getCustomerName = createSelector(globalSelector, (state) => state.customerName);

export const getTourElement = createSelector(globalSelector, (state) => state.tourSettings);

export const isTourSettingsLoaded = createSelector(globalSelector, (state) => state.tourSettings.state === LoadingState.LOADED);

export const globalQuery = {
  getIsUserOnline,
  getUserLanguage,
  getNewFeaturePreferences,
  getDefaultTimeZone,
  getDeviceGroups,
  getDefaultDeviceGroup,
  getSearchResultSize,
  getEngineVersion,
  getCustomerName,
  getTourElement,
  isTourSettingsLoaded,
};
