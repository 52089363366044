import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthFacade } from './store/auth.facade';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *ampHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *ampHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
  selector: '[ampHasAnyPermissions]',
})
export class HasAnyPermissionDirective implements OnInit, OnDestroy {
  private permissions: string[];
  private userPermissions: string[] = [];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(private authFacade: AuthFacade, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  @Input()
  set ampHasAnyPermissions(value: string | string[]) {
    this.permissions = typeof value === 'string' ? [<string>value] : <string[]>value;
    this.updateView();
  }

  ngOnInit() {
    this.authFacade.authenticatedUserPermissions$.pipe(takeUntil(this.componentDestroyed$)).subscribe((permissions: string[]) => {
      this.userPermissions = permissions ? [...permissions] : [];
      this.updateView();
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private updateView(): void {
    if (this.userHasPermission()) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  private userHasPermission() {
    // if the permissions required are empty, that means no permissions are required
    if (!this.permissions || this.permissions.length === 0) {
      return true;
    }

    for (let i = 0; i < this.permissions.length; i++) {
      for (let j = 0; j < this.userPermissions.length; j++) {
        if (this.userPermissions[j] === this.permissions[i]) {
          return true;
        }
      }
    }
    return false;
  }
}
