import { DEFAULT_SLIDE_DIALOG_CONFIG, IModalConfig, ISlideData, ISlideDialogData, ISlideDialogLabels, ModalService, SlideDialogComponent, ThemeType } from '@activia/ngx-components';
import { OverlayConfig } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';

import { GlobalFacade } from '../store/global.facade';

import { INewFeaturePreferences } from './new-feature-preferences.interface';
import { NewFeatureData } from './new-feature-tour.interface';

@Injectable({ providedIn: 'root' })
export class NewFeatureService {
  // Dismiss new feature dialog and not show again in the next 24 hours by default
  private DISMISS_TIME_IN_HOURS = 24;

  constructor(private modalService: ModalService, private globalFacade: GlobalFacade, private translate: TranslocoService) {}

  /**
   * Open new feature tour dialog
   */
  openNewFeatureTour(preferences: INewFeaturePreferences, autoOpen: boolean) {
    const dt = preferences.pausedAtTime;
    if (dt !== undefined) {
      dt.setHours(dt.getHours() + this.DISMISS_TIME_IN_HOURS);
    }

    // Conditions to open the dialog:
    // 1. If it's opened from the button on ribbon
    // 2. If it's first time user logs in (optOut is undefined)
    // 3. If user is not opted out, and still has unseen features, and certain time has
    //    passed since last time he saw the update
    if (!autoOpen || preferences.optOut === undefined || (!preferences.optOut && preferences.pausedAtStepId !== NewFeatureData[NewFeatureData.length - 1].id && dt.getTime() < new Date().getTime())) {
      // TODO: add GA tracking back once decoupling task is completed
      // this.sendGAEvent(NewFeatureGAEventActions.OPEN, autoOpen ? 'Auto Display' : 'Manual Display');
      this.openTour(preferences);
    }
  }

  /**
   * Open new feature tour dialog
   */
  openTour(preferences: INewFeaturePreferences): void {
    const dialogData: ISlideDialogData = {
      theme: ThemeType.PRIMARY,
      slides: this.getTranslatedNewFeatureData(),
      labels: this.getLabels(),
      startSlideIndex: preferences.pausedAtStepId,
    };

    const dialogConfig: IModalConfig<ISlideDialogData> = {
      ...DEFAULT_SLIDE_DIALOG_CONFIG,
      data: dialogData,
    };

    const overlayConfig: OverlayConfig = {
      width: '950px',
    };

    const modalRef = this.modalService.open(SlideDialogComponent, dialogConfig, overlayConfig);

    (modalRef.componentInstance as SlideDialogComponent).slideViewed.subscribe((_) => {
      // todo GA
    });

    (modalRef.componentInstance as SlideDialogComponent).slideshowDisabled.subscribe((_) => {
      const newPreferences: INewFeaturePreferences = {
        ...preferences,
        optOut: true,
        pausedAtStepId: _.id,
        pausedAtTime: new Date(),
      };

      this.globalFacade.updateNewFeatureSettings(newPreferences);
    });

    // This will be triggered when user clicks Finish Later or when user completes the tour
    // It will not be triggered when user opts out because of the condition in takeUntil
    modalRef.closed.pipe(takeUntil((modalRef.componentInstance as SlideDialogComponent).slideshowDisabled.pipe(take(1)))).subscribe((_) => {
      const lastViewedSlide = modalRef.componentInstance.currentSlide;
      // TODO: add GA tracking back once decoupling task is completed
      // this.sendGAEvent(lastViewedSlide.id === NewFeatureData[NewFeatureData.length - 1].id ? NewFeatureGAEventActions.COMPLETED : NewFeatureGAEventActions.DISMISSED);

      const newPreferences: INewFeaturePreferences = {
        ...preferences,
        pausedAtStepId: lastViewedSlide.id,
        pausedAtTime: new Date(),
      };

      if (newPreferences.optOut === undefined) {
        // Meaning this is the first time user sees this tour, set opt out to default value
        // false
        newPreferences.optOut = false;
      }
      this.globalFacade.updateNewFeatureSettings(newPreferences);
    });
  }

  private getLabels(): ISlideDialogLabels {
    return {
      start: this.translate.translate('button.start'),
      notInterested: this.translate.translate('dashboard.new-feature-tour.dialog.not-interested'),
      optOut: this.translate.translate('dashboard.new-feature-tour.dialog.opt-out'),
      previous: this.translate.translate('button.prev'),
      next: this.translate.translate('button.next'),
      finishLater: this.translate.translate('dashboard.new-feature-tour.dialog.finish-later'),
      finished: this.translate.translate('dashboard.new-feature-tour.dialog.tour-completed'),
    };
  }

  private getTranslatedNewFeatureData(): Array<ISlideData> {
    const data: Array<ISlideData> = [...NewFeatureData];

    data.forEach((datum, index) => {
      datum.slideTitle = this.translate.translate(`dashboard.new-feature-tour.dialog.data.${index}.title`);
      datum.slideDescription = this.translate.translate(`dashboard.new-feature-tour.dialog.data.${index}.description`);
    });

    return data;
  }
}
