import { Injectable, Inject } from '@angular/core';
import { combineLatest, timer, Observable } from 'rxjs';
import { filter, map, takeWhile, withLatestFrom } from 'rxjs/operators';
import { UserDTO } from '@activia/cm-api';
import { MESSENGER_CONFIG } from '../messenger-token';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  get userAndOnline$(): Observable<{ user: UserDTO; isUserOnline: boolean }> {
    return combineLatest([this.messengerConfig.authenticatedUser$, this.messengerConfig.isUserOnline$]).pipe(
      map(([user, isUserOnline]) => ({ user, isUserOnline } as { user: UserDTO; isUserOnline: boolean }))
    );
  }

  constructor(@Inject(MESSENGER_CONFIG) private messengerConfig) {}

  /**
   * Create a timer observable that emits value only when user is logged in and is connected to the network.
   * The timer is terminated when user logs out.
   * The timer is paused when user is logged in but lost network connection.
   *
   * @param refreshInterval Refresh interval in millisecond
   * @param dueTime(optional) Delay time in milliseconds to start the timer, default set to 0 (start immediately)
   */
  timer$(refreshInterval: number, dueTime = 0) {
    return timer(dueTime, refreshInterval).pipe(
      withLatestFrom(this.userAndOnline$),
      takeWhile(([_, data]) => !!data.user),
      filter(([_, data]) => data.isUserOnline),
      map(([time, _]) => time)
    );
  }
}
