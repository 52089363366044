// core services from cm-api
import { ApiModule, Configuration, DeviceService, AuthenticationService, MetricsService } from '@activia/cm-api';
import { Inject, NgModule } from '@angular/core';
import { Configuration as ScreenshotConfiguration, PlayerAPIModule } from '@activia/device-screenshot-api';
import { MAP_API_TOKEN } from '@activia/geo';
import {
  ButtonColorType,
  ButtonModule,
  CoreModule,
  IconModule,
  ITheme,
  LayoutMode,
  LayoutModule,
  SlideDialogModule,
  TourModule,
  WINDOW,
  CALENDAR_CONFIG,
  TourNavButton,
  IErrorInfo,
  AsyncDataModule,
  SkeletonModule,
} from '@activia/ngx-components';
import { AvnThemes } from '@activia/theming/themes';
import { AMP_DMB_INFO_TOKEN, AMP_ENVIRONMENT_INFO_TOKEN, AMP_USERINFO_TOKEN, AmpAnalyticsModule, AmpDmbInfoProvider, AmpEnvironmentProvider, AmpUserInfoProvider } from '@amp/analytics';
import { AuthFacade, AuthModule, Logout, LogoutFail } from '@amp/auth';
import {
  DEFAULT_SITE_MANAGEMENT_CONFIG,
  ISiteManagementConfig,
  ISiteMonitoringConfig,
  MONITORING_LIST_TEMPLATES,
  SITE_MANAGEMENT_MODULE_CONFIG,
  SITE_MONITORING_MODULE_CONFIG
} from '@amp/environment';
import { ErrorModule } from '@amp/error';
import { FeatureToggleModule } from '@amp/feature-toggle';
import { GlobalFacade, GlobalModule } from '@amp/global';
import { MESSENGER_CONFIG, MessengerModule, MessengerNotificationService } from '@amp/messenger';
import { RouterStoreModule } from '@amp/router-store';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
// store and state
import { Action, ActionReducer, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { Ng2Webstorage } from 'ngx-webstorage';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';

// App is our top level component
import { AppComponent } from './app.component';
/*
 * Platform and Environment providers/directives/pipes
 */
import { ROUTES } from './app.routes';
import { AMP_CONFIG_PROVIDERS, AMP_GRAFANA_PROVIDER, AMP_LEGACY_PROVIDER, AMP_WSS_PROVIDER } from './config/amp-config-initializer';
import { ConnectorLineModule } from '@activia/dataviz';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { BehaviorSubject, of } from 'rxjs';
import { I18N_PROVIDERS } from './config/i18n-config';
import { CmApiCustomHttpParameterCodec } from './config/cm-api-custom-http-parameter-codec';

/** meta reducer that clear store when logout */
export const clearStoreWhenLogout = <T, V extends Action = Action>(reducer: ActionReducer<T, V>): ActionReducer<T, V> => (state, action) => {
    if (action.type === Logout.type || action.type === LogoutFail.type) {
      const defaultState: any = {};
      Object.keys(state).forEach((sKey) => {
        if (sKey === 'router') {
          defaultState[sKey] = { ...state[sKey] };
        }
      });
      // remove everything that is not the router state
      state = defaultState;
    }
    return reducer(state, action);
  };

export const configurationFactory = () => {
  const config = new Configuration({ apiKeys: {}, encoder: new CmApiCustomHttpParameterCodec() });
  return config;
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    MessengerModule.forRoot({
      provide: MESSENGER_CONFIG,
      useFactory: (authFacade: AuthFacade, globalFacade: GlobalFacade) => ({ authenticatedUser$: authFacade.authenticatedUser$, isUserOnline$: globalFacade.isUserOnline$ }),
      deps: [AuthFacade, GlobalFacade],
    }),
    Ng2Webstorage.forRoot({ prefix: 'cm', separator: '-' }),
    ApiModule.forRoot(configurationFactory),
    PlayerAPIModule.forRoot(() => new ScreenshotConfiguration({ apiKeys: {} })),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    StoreModule.forRoot({}, { metaReducers: environment.development ? [storeFreeze, clearStoreWhenLogout] : [clearStoreWhenLogout] }),
    EffectsModule.forRoot([]),
    environment.development || environment.staging ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    AuthModule,
    GlobalModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
    LayoutModule.forRoot({ mode: LayoutMode.COLLAPSIBLE, autoExpandDelay: 500, autoExpandCloseDelay: 500, width: 200, opened: false }),
    // PortalModule,
    SlideDialogModule,
    CoreModule.forRoot({ availableThemes: AvnThemes as ITheme[] }),
    FeatureToggleModule.forRoot(),
    TranslocoMessageFormatModule.forRoot(),
    ButtonModule,
    IconModule,
    SkeletonModule.forRoot(),
    TourModule.forRoot({
      buttonLaterColorType: ButtonColorType.link,
      buttonNeverShowAgainColorType: ButtonColorType.link,
      nextLabel: null,
      previousLabel: null,
      navButtonOrder: [TourNavButton.previous, TourNavButton.next, TourNavButton.later, TourNavButton.never],
      elementConfig: { tourMaxWidth: 500 },
      isTourPagination: true,
    }),
    ErrorModule,
    RouterStoreModule,
    ConnectorLineModule.forRoot({
      lineOptions: {
        size: 2,
        startPlug: 'square',
        endPlug: 'behind',
      },
    }),
    AmpAnalyticsModule.forRoot({
      googleAnalytics: {
        apiKey: environment.googleAnalyticsConfig.apiKey,
      },
    }),
    AsyncDataModule.forRoot({
      loaderIcon: 'notification:sync',
      errorHandler: {
        useFactory: (messengerService: MessengerNotificationService) => ({
          handleError: (errorInfo: IErrorInfo) => {
            messengerService.showErrorMessage(errorInfo.message, errorInfo.messageParams, errorInfo.title);
          },
        }),
        deps: [MessengerNotificationService],
      },
    }),
  ],
  providers: [
    // analytics: todo move to shell folder / shared module
    {
      provide: AMP_USERINFO_TOKEN,
      useFactory: (authFacade: AuthFacade) => {
        const ampRoleDimensionProvider = new AmpUserInfoProvider();
        // todo why would state emit null / undefined...
        ampRoleDimensionProvider.role$ = authFacade.authenticatedUserRoles$.pipe(map((r) => r && r[0] && r[0].name));
        ampRoleDimensionProvider.username$ = authFacade.authenticatedUser$.pipe(map((user) => user?.username));
        return ampRoleDimensionProvider;
      },
      deps: [AuthFacade],
    },
    {
      provide: AMP_ENVIRONMENT_INFO_TOKEN,
      useFactory: (globalFacade: GlobalFacade) => {
        const ampEnvironmentProvider = new AmpEnvironmentProvider();
        ampEnvironmentProvider.ampApp$ = of('amp');
        ampEnvironmentProvider.ampEngineVersion$ = globalFacade.engineVersion$;
        ampEnvironmentProvider.customerName$ = globalFacade.customerName$;
        return ampEnvironmentProvider;
      },
      deps: [GlobalFacade],
    },
    {
      provide: AMP_DMB_INFO_TOKEN,
      useFactory: () => {
        const ampDmbInfoProvider = new AmpDmbInfoProvider();
        ampDmbInfoProvider.managerId$ = new BehaviorSubject(undefined);
        return ampDmbInfoProvider;
      },
    },
    AuthenticationService,
    DeviceService,
    MetricsService,
    AMP_CONFIG_PROVIDERS,
    AMP_GRAFANA_PROVIDER,
    AMP_LEGACY_PROVIDER,
    AMP_WSS_PROVIDER,
    I18N_PROVIDERS,
    { provide: CALENDAR_CONFIG, useValue: { daysSizePx: 45 } },
    { provide: MAP_API_TOKEN, useValue: environment.googleMapAPIKey },
    { provide: MONITORING_LIST_TEMPLATES, useValue: environment.monitoringListTemplates },
    {
      provide: SITE_MONITORING_MODULE_CONFIG,
      useValue: {
        profile: 'support',
        isStandaloneApp: false,
        moduleBasePath: ['app', 'site-monitoring'],
        enableDashboard: true,
        dashboardSitesThreshold: 10,
        defaultToOptimisticView: false,
        showMap: true,
        showBoardPlayerMapping: true,
        showPlayerDeprecationWarning: true,
        siteSearchMinChars: 3,
      } as ISiteMonitoringConfig,
    },
    {
      provide: SITE_MANAGEMENT_MODULE_CONFIG,
      useValue: {
        ...DEFAULT_SITE_MANAGEMENT_CONFIG,
      } as ISiteManagementConfig,
    },
  ],
})
export class AppModule {
  // inject the service to instanciate it
  constructor(@Inject(WINDOW) _window: Window, store: Store<any>) {
    registerLocaleData(localeFr);
    registerLocaleData(localeEs);
    registerLocaleData(localeDe);

    // If cypress is running, add the store on the window so that we can use it to dispatch action
    // this is useful to simulate incoming websocket data for example
    if (_window['Cypress']) {
      _window['cypress.store'] = store;
    }
  }
}
