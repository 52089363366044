import { InjectionToken } from '@angular/core';

export interface ISiteMonitoringConfig {
  /**
   * Specify the profile to run the app for.
   * One set of app preferences will be created per profile
   * */
  profile: 'support' | 'client';

  /**
   * Indicates if the module is run in a standalone application (versus in AMP with all features modules)
   * */
  isStandaloneApp: boolean;

  /** app base path for the module */
  moduleBasePath: string[];

  /** Turns on/off the dashboard **/
  enableDashboard: boolean;

  /** Show the dashboard based on the number of sites the user has access to. Dashboard should be enabled. Leave empty for disabling this functionality **/
  dashboardSitesThreshold?: number;

  /**
   * optional manager. The user id, will be used by cm to limit the scope
   * of sites shown to the user.
   */
  dmbManagerId?: number;

  /**
   * show the mapping of players to each display
   */
  showBoardPlayerMapping: boolean;

  /**
   * show player deprecation warning
   */
  showPlayerDeprecationWarning: boolean;

  /**
   * controls the aggregation of warnings into ok state for key metrics and charts that are used
   * outside of the key metrics viewer.
   */
  defaultToOptimisticView: boolean;

  /** turns on/off the maps */
  showMap: boolean;

  /**
   * minimum chars allow for site search
   */
  siteSearchMinChars: number;
}

export const SITE_MONITORING_MODULE_CONFIG = new InjectionToken<ISiteMonitoringConfig>('SITE_MONITORING_MODULE_CONFIG');
