import { HttpClient } from '@angular/common/http';

export const downloadImage = (_http: HttpClient, fileUrl: string, fileName?: string) => {
  const extensionIdx = fileUrl.lastIndexOf('.');
  const extension = fileUrl.slice(extensionIdx + 1);

  if ((fileName ?? '').length === 0) {
    // TODO: on Mac or Linux is it forward or back slash??
    const fileNameIdx = fileUrl.lastIndexOf('/');
    fileName = fileUrl.slice(fileNameIdx + 1, extensionIdx);
  }

  _http.get(fileUrl, { responseType: 'arraybuffer' }).subscribe((res) => {
    const blob = new Blob([res], { type: `image/${extension}` });
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.${extension}`;
    link.click();
  });
};
