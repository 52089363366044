/**
 * This enum defines the keys of preferences stored in the backend
 * Note that the key (value of each enum) must match the hierarchy of
 * the field in IUserPreferences, in order to ensure that parsing/setting
 * data in global effect fetch works
 */
import { DeviceGroupDTO, KeyValueDTO } from '@activia/cm-api';

import { ITourElementSettings } from './global.reducer';

export enum UserPreferenceKeys {
  GLOBAL_USERLANGUAGE = 'global.userLanguage',
  GLOBAL_NEW_FEATURE = 'global.newFeature',
  GLOBAL_TOUR_ELEMENT_SETTINGS = 'global.tourElementSettings',
  NOTIFICATION_SHOWTOAST = 'notification.showToast',
  DEFAULT_DEVICE_GROUP = 'default.device.group',
  SEARCH_RESULT_SIZE = 'net.activia.cm.gui.user.UserPreferences.pagesize',
  DEFAULT_TIME_ZONE = 'net.activia.cm.gui.user.UserPreferences.tmz',
  MONITORING_RECENT_DEVICES = 'net.activia.cm.gui.user.monitoring.recentDevices',
  MONITORING_FAVORITE_DEVICES = 'net.activia.cm.gui.user.monitoring.favoriteDevices',
  MONITORING_SEARCH_DEVICES = 'net.activia.cm.gui.user.monitoring.searchDevices',
  MONITORING_USER_LISTS = 'net.activia.cm.gui.user.monitoring.userLists',
  MONITORING_SHARED_LISTS = 'net.activia.cm.gui.user.monitoring.sharedLists',
}

/**
 * This interface only contains the user preferences that need to be
 * fetched when the application is initialized
 */
export interface IUserPreferences {
  global?: {
    userLanguage?: string;
    newFeature?: { optOut?: boolean | string; pausedAtStepId?: number | string; pausedAtTime?: Date | string };
    defaultDeviceGroup?: Partial<DeviceGroupDTO>;
    searchResultSize?: number;
    defaultTimeZone?: string;
    tourSettings?: ITourElementSettings;
  };
  notification?: { showToast?: boolean | string };
}

/**
 * This object contains the initial values of the user preferences when the
 * preferences are not found in the backend storage
 */
export const INITIAL_USER_PREFERENCES: IUserPreferences = {
  global: {
    userLanguage: 'en',
    // Set the initial values for new feature preferences to undefined so we
    // know that user has never seen the new feature tour, and will display
    // the tour right after user logs in
    newFeature: {
      optOut: undefined,
      pausedAtStepId: undefined,
      pausedAtTime: undefined,
    },
    defaultDeviceGroup: undefined,
    searchResultSize: undefined,
    defaultTimeZone: '',
    tourSettings: {
      tourId: null,
      lastViewElement: null,
      neverShowAgain: null,
      tourAlreadyView: [],
    },
  },
  notification: {
    showToast: true,
  },
};

export const assignUserPreference = (userPreferences: IUserPreferences, pref: KeyValueDTO) => {
  switch (pref.key) {
    case UserPreferenceKeys.GLOBAL_USERLANGUAGE:
      userPreferences.global.userLanguage = JSON.parse(pref.value);
      break;
    case UserPreferenceKeys.GLOBAL_NEW_FEATURE:
      userPreferences.global.newFeature = JSON.parse(pref.value);
      break;
    case UserPreferenceKeys.DEFAULT_DEVICE_GROUP:
      userPreferences.global.defaultDeviceGroup = JSON.parse(pref.value);
      break;
    case UserPreferenceKeys.NOTIFICATION_SHOWTOAST:
      userPreferences.notification.showToast = JSON.parse(pref.value);
      break;
    case UserPreferenceKeys.SEARCH_RESULT_SIZE:
      userPreferences.global.searchResultSize = JSON.parse(pref.value);
      break;
    case UserPreferenceKeys.DEFAULT_TIME_ZONE:
      userPreferences.global.defaultTimeZone = pref.value;
      break;
    case UserPreferenceKeys.GLOBAL_TOUR_ELEMENT_SETTINGS:
      userPreferences.global.tourSettings = JSON.parse(pref.value);
      break;
    default:
      break;
  }
};
