import { Inject, Injectable, Optional } from '@angular/core';
import { AnalyticsService } from '@activia/ngx-components';
import { HttpErrorResponse } from '@angular/common/http';
import { take, takeUntil } from 'rxjs/operators';
import { AuthFacade } from '@amp/auth';
import { TranslocoService } from '@ngneat/transloco';
import { FeatureToggleService } from '@amp/feature-toggle';
import { RouterFacade } from '@amp/router-store';
import { MessengerNotificationService } from '@amp/messenger';
import { ANALYTICS_OPTIONS, IAnalyticsOptions } from '@amp/analytics';

@Injectable({ providedIn: 'root' })
export class ErrorHandlingService {
  username: string;

  constructor(
    @Inject(ANALYTICS_OPTIONS) @Optional() private analyticsOptions: IAnalyticsOptions,
    private analyticsService: AnalyticsService,
    private translate: TranslocoService,
    private messenger: MessengerNotificationService,
    private routerFacade: RouterFacade,
    private featureToggleService: FeatureToggleService,
    private authFacade: AuthFacade
  ) {
    this.authFacade.authenticatedUser$.subscribe((user) => {
      this.username = user?.username;
    });
  }

  /**
   * @param err HttpErrorResponse object
   * @param toastTitle (Optional) i18n key for the error message title that will be displayed in the toast
   * @param toastMessage (Optional) i18n key for the error message body that will be displayed in the toast
   * @param toastMessageParams (Optional) Params for the i18n key of the error message body
   * @param uniqueToast (Optional) See the explanation for uniqueToast field in IToastConfig
   */
  catchError(err: HttpErrorResponse, toastTitle?: string, toastMessage?: string, toastMessageParams?: any, uniqueToast?: boolean) {
    this.sendGATrack(err);
    this.showToast(err, toastTitle, toastMessage, toastMessageParams, uniqueToast);
  }

  sendGATrack(err: HttpErrorResponse) {
    if (this.analyticsOptions?.googleAnalytics?.apiKey) {
      const gaData = {
        message: err.message,
        detail: err.error || '',
        username: this.username,
      };
      this.analyticsService.trackError({ exDescription: JSON.stringify(gaData), exFatal: false });
    }
  }

  private showToast(err: HttpErrorResponse, toastTitle?: string, toastMessage?: string, toastMessageParams?: any, uniqueToast?: boolean) {
    if ((!!toastTitle || !!toastMessage) && this.featureToggleService.isOn('toast.showErrorToast')) {
      const actions = [
        {
          id: 'copy',
          icon: 'fa:copy',
          title: this.translate.translate('global.copy-error-log'),
        },
      ];

      // Only show Send Log action in toast if the feature is on and the user is logged in
      if (this.featureToggleService.isOn('support.sendErrorLog') && !!this.username) {
        actions.push({
          id: 'report',
          icon: 'content:drafts',
          title: this.translate.translate('global.send-error-log'),
        });
      }

      this.routerFacade.currentRoute$.pipe(take(1)).subscribe((currentRoute) => {
        const errData = JSON.stringify({
          errMsg: toastMessage ? this.translate.translate(toastMessage, toastMessageParams) : undefined,
          err,
          username: this.username,
          pageUrl: currentRoute?.url,
        });

        this.messenger.showErrorMessageWithActions(actions, toastTitle, toastMessage, toastMessageParams, uniqueToast).then(({ toastRef, toast }) => {
          if (toast) {
            toast.clicked.pipe(takeUntil(toast.destroy)).subscribe((act) => {
              if (act.action.id === 'copy') {
                navigator.clipboard.writeText(errData);
              } else {
                // TODO: if user is already in the support page, append the error log data instead of redirecting again
                this.routerFacade.navigate({ path: ['app', 'support', 'report-error'], extras: { state: { errorLog: errData } } });
                toastRef.destroy();
              }
            });
          }
        });
      });
    }
  }
}
