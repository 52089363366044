import { ISlideData } from '@activia/ngx-components';

export const NewFeatureData: Array<ISlideData> = [
  {
    id: 0,
    slideshowStarter: true,
    slideTitle: '',
    slideDescription: '',
    mediaType: 'image',
    mediaValue: 'assets/img/background.jpeg',
    mediaPosition: 'top',
    showDialogHeader: true,
    showDialogHeaderCloseIcon: false,
  },
  {
    id: 1,
    slideTitle: '',
    slideDescription: '',
    mediaType: 'image',
    mediaValue: 'assets/img/dashboard/AmpLogo.png',
    mediaPosition: 'top',
  },
  {
    id: 2,
    slideTitle: '',
    slideDescription: '',
    mediaType: 'image',
    mediaValue: 'assets/img/dashboard/navigation.gif',
    mediaPosition: 'top',
  },
  {
    id: 3,
    slideTitle: '',
    slideDescription: '',
    mediaType: 'image',
    mediaValue: 'assets/img/dashboard/dashboard.gif',
    mediaPosition: 'top',
  },
  {
    id: 4,
    slideTitle: '',
    slideDescription: '',
    mediaType: 'image',
    mediaValue: 'assets/img/dashboard/notifications.gif',
    mediaPosition: 'top',
  },
  {
    id: 5,
    slideTitle: '',
    slideDescription: '',
    mediaType: 'icon',
    mediaValue: 'fa:rocket',
    mediaPosition: 'top',
  },
];
