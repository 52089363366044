import { DeviceGroupDTO } from '@activia/cm-api';
import { INewFeaturePreferences } from '../new-feature/new-feature-preferences.interface';
import { AsyncData, LoadingState } from '@activia/ngx-components';
import * as GlobalAction from './global.action';
import * as AuthAction from '@amp/auth';
import { Action, createReducer, on } from '@ngrx/store';

/**
 * Define the tour settings
 */
export interface ITourElementSettings {
  tourId: string;
  lastViewElement: string;
  neverShowAgain: boolean;
  tourAlreadyView: string[];
}

/**
 * The global state
 * responsible for storing app wide state that is unrelated to auth
 * i.e global preferences or display parameters
 */
export interface IGlobalState {
  isUserOnline: boolean;
  lang: string;
  newFeaturePreferences: INewFeaturePreferences;
  /** Available device groups within the current user's role */
  deviceGroups: Array<DeviceGroupDTO>;
  defaultDeviceGroup: DeviceGroupDTO;
  searchResultSize: number;
  defaultTimeZone: string;
  engineVersion: string;
  customerName: string;
  tourSettings: AsyncData<ITourElementSettings>;
}

export const initialGlobalState: IGlobalState = {
  isUserOnline: true,
  lang: null,
  newFeaturePreferences: {
    preferencesLoaded: false,
    optOut: undefined,
    pausedAtStepId: undefined,
    pausedAtTime: undefined,
  },
  deviceGroups: [],
  defaultDeviceGroup: undefined,
  searchResultSize: 50,
  defaultTimeZone: '',
  engineVersion: undefined,
  customerName: undefined,
  tourSettings: {
    data: { tourId: null, neverShowAgain: false, lastViewElement: null, tourAlreadyView: [] },
    state: LoadingState.INIT,
  },
};

const reducer = createReducer<IGlobalState>(
  initialGlobalState,

  on(GlobalAction.UserConnectionStatusUpdate, (state, action) => ({
    ...state,
    isUserOnline: action.isUserOnline,
  })),

  on(GlobalAction.UserPreferencesFetchSuccess, (state, action) => {
    const data = action.preferences.global.newFeature;
    const val: INewFeaturePreferences = { ...initialGlobalState.newFeaturePreferences };
    val.preferencesLoaded = true;
    if (data.optOut !== undefined) {
      val.optOut = typeof data.optOut === 'string' ? JSON.parse(data.optOut) : data.optOut;
      val.pausedAtStepId = typeof data.pausedAtStepId === 'string' ? JSON.parse(data.pausedAtStepId) : data.pausedAtStepId;
      val.pausedAtTime = typeof data.pausedAtTime === 'string' ? new Date(data.pausedAtTime) : data.pausedAtTime;
    }

    return {
      ...state,
      lang: action.preferences.global.userLanguage,
      newFeaturePreferences: val,
      defaultDeviceGroup: action.preferences.global.defaultDeviceGroup,
      searchResultSize: action.preferences.global.searchResultSize,
      defaultTimeZone: action.preferences.global.defaultTimeZone,
      tourSettings: { state: LoadingState.LOADED, data: action.preferences.global.tourSettings },
    };
  }),

  on(GlobalAction.UserLanguageUpdateSuccess, (state, action) => ({
    ...state,
    lang: action.language,
  })),

  on(GlobalAction.NewFeatureSettingsUpdateSuccess, (state, action) => ({
    ...state,
    newFeaturePreferences: {
      ...state.newFeaturePreferences,
      ...action.preference,
    },
  })),

  on(GlobalAction.UserPreferencesUpdateSuccess, (state, action) => ({
      ...state,
      ...action.preferences,
      defaultDeviceGroup: action.preferences.defaultDeviceGroup,
    })),

  on(GlobalAction.SystemInformationFetchSuccess, (state, action) => ({
    ...state,
    engineVersion: action.info.cmEngineVersion,
    customerName: action.info.customerName,
  })),

  on(GlobalAction.DeviceGroupsForRoleFetchSuccess, (state, action) => {
    const newState = {
      ...state,
      deviceGroups: action.deviceGroups,
    };
    const found = action.deviceGroups.find((dg) => newState.defaultDeviceGroup && dg.id === newState.defaultDeviceGroup.id);
    if (!found) {
      newState.defaultDeviceGroup = undefined;
    }
    return newState;
  }),

  on(GlobalAction.TourElementUpdate, (state, action) => ({
    ...state,
    tourSettings: {
      data: {
        tourId: action.tourId,
        lastViewElement: action.lastViewElement,
        neverShowAgain: action.neverShowAgain,
        tourAlreadyView: action.tourAlreadyView || [],
      },
      state: LoadingState.LOADING,
    },
  })),

  on(GlobalAction.TourElementUpdateSuccess, (state, _) => ({
    ...state,
    tourSettings: {
      data: {
        ...state.tourSettings.data,
      },
      state: LoadingState.LOADED,
    },
  })),

  on(GlobalAction.TourElementUpdateFail, (state, action) => ({
    ...state,
    tourSettings: {
      data: { ...state.tourSettings.data },
      state: { errorMsg: action.errorMessage },
    },
  })),

  on(AuthAction.LogoutSuccess, (state, _) => ({
    ...state,
    lang: null,
  }))
);

export const globalReducer = (state = initialGlobalState, action: Action): IGlobalState => reducer(state, action);
