/* eslint-disable @typescript-eslint/prefer-for-of */
import { RoleDTO } from '@activia/cm-api';
import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthFacade } from './store/auth.facade';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *ampHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *ampHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
  selector: '[ampHasAnyAuthority]',
})
export class HasAnyAuthorityDirective implements OnInit, OnDestroy {
  private authorities: string[];
  private roles: string[] = [];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(private authFacade: AuthFacade, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  @Input()
  set ampHasAnyAuthority(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [<string>value] : <string[]>value;
    this.updateView();
  }

  ngOnInit(): void {
    this.authFacade.authenticatedUserRoles$.pipe(takeUntil(this.componentDestroyed$)).subscribe((roles: Array<RoleDTO>) => {
      this.roles = roles ? [...roles].map((r) => r.name) : [];
      this.updateView();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private updateView(): void {
    if (this.isAuthorityInRoles()) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  private isAuthorityInRoles(): boolean {
    // if the authorities required are empty, that means no authorities are required
    if (!this.authorities || this.authorities.length === 0) {
      return true;
    }

    for (let i = 0; i < this.authorities.length; i++) {
      for (let j = 0; j < this.roles.length; j++) {
        if (this.roles[j] === this.authorities[i]) {
          return true;
        }
      }
    }
    return false;
  }
}
