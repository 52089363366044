import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Actions, ofType } from '@ngrx/effects';
import {
  ROUTER_CANCEL,
  ROUTER_ERROR,
  ROUTER_NAVIGATED,
  ROUTER_NAVIGATION,
  ROUTER_REQUEST,
  RouterRequestAction,
  RouterNavigationAction,
  RouterNavigatedAction,
  RouterCancelAction,
} from '@ngrx/router-store';
import { getCurrentRoute } from './router.selectors';
import { IRouterStateUrl, NgrxRoute, RouterState } from './router.interfaces';
import { map, pairwise } from 'rxjs/operators';
import * as RouterAction from './router.actions';
import { RouterErrorAction } from '@ngrx/router-store/src/actions';

@Injectable({ providedIn: 'root' })
export class RouterFacade {
  currentRoute$ = this.store.select(getCurrentRoute);
  previousRoute$ = this.currentRoute$.pipe(
    pairwise(),
    map(([prev, _]) => prev)
  );

  /**
   * At the start of each navigation, the router will dispatch a ROUTER_REQUEST action.
   */
  routerRequest$ = this.actions$.pipe(ofType<RouterRequestAction<IRouterStateUrl>>(ROUTER_REQUEST));

  /**
   * During navigation, before any guards or resolvers run, the router will dispatch a ROUTER_NAVIGATION action.
    If you want the ROUTER_NAVIGATION to be dispatched after guards or resolvers run, change the Navigation Action Timing.
   */
  routerNavigation$ = this.actions$.pipe(ofType<RouterNavigationAction<IRouterStateUrl>>(ROUTER_NAVIGATION));

  /**
   * After a successful navigation, the router will dispatch a ROUTER_NAVIGATED action.
   */
  routerNavigated$ = this.actions$.pipe(ofType<RouterNavigatedAction<IRouterStateUrl>>(ROUTER_NAVIGATED));

  /**
   * When the navigation is cancelled, for example due to a guard saying that the user cannot access the requested page, the router will dispatch a ROUTER_CANCEL action.
    The action contains the store state before the navigation. You can use it to restore the consistency of the store.
   */
  routerCancel$ = this.actions$.pipe(ofType<RouterCancelAction<any, IRouterStateUrl>>(ROUTER_CANCEL));

  /**
   * When there is an error during navigation, the router will dispatch a ROUTER_ERROR action.
    The action contains the store state before the navigation. You can use it to restore the consistency of the store.
   */
  routerError$ = this.actions$.pipe(ofType<RouterErrorAction<any, IRouterStateUrl>>(ROUTER_ERROR));

  /** Navigates to the specified route **/
  navigate(route: NgrxRoute) {
    this.store.dispatch(RouterAction.RouterGo({ to: route }));
  }

  /** Navigates back **/
  navigateBack() {
    this.store.dispatch(RouterAction.RouterBack());
  }

  /** Navigates forward **/
  navigateForward() {
    this.store.dispatch(RouterAction.RouterForward());
  }

  constructor(private store: Store<RouterState>, private actions$: Actions) {}
}
