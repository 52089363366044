import { IAnalyticsDimensions, IDimensionsProvider } from '@activia/ngx-components';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AmpUserInfoProvider {
  username$: Observable<string> | BehaviorSubject<string>;
  role$: Observable<string> | BehaviorSubject<string>;
}

@Injectable({
  providedIn: 'root',
})
export class AmpEnvironmentProvider {
  ampApp$: Observable<string>;
  ampEngineVersion$: Observable<string> | BehaviorSubject<string>;
  customerName$: Observable<string> | BehaviorSubject<string>;
}

@Injectable({
  providedIn: 'root',
})
export class AmpDmbInfoProvider {
  managerId$: Observable<number> | BehaviorSubject<number>;
}

export const AMP_USERINFO_TOKEN = new InjectionToken<AmpUserInfoProvider>('AMP_USERINFO_TOKEN');
export const AMP_ENVIRONMENT_INFO_TOKEN = new InjectionToken<AmpEnvironmentProvider>('AMP_ENVIRONMENT_INFO_TOKEN');
export const AMP_DMB_INFO_TOKEN = new InjectionToken<AmpDmbInfoProvider>('AMP_DMB_INFO_TOKEN');

@Injectable()
export class CmDimensionsProviderService implements IDimensionsProvider {
  constructor(
    @Inject(AMP_USERINFO_TOKEN) private ampRoleDimensionProvider: AmpUserInfoProvider,
    @Inject(AMP_ENVIRONMENT_INFO_TOKEN) private ampEnvironmentProvider: AmpEnvironmentProvider,
    @Inject(AMP_DMB_INFO_TOKEN) private ampDmbInfoProvider: AmpDmbInfoProvider
  ) {}

  get dimensions$(): Observable<IAnalyticsDimensions> {
    return combineLatest(
      this.ampRoleDimensionProvider.username$,
      this.ampRoleDimensionProvider.role$,
      this.ampEnvironmentProvider.ampApp$,
      this.ampEnvironmentProvider.ampEngineVersion$,
      this.ampEnvironmentProvider.customerName$,
      this.ampDmbInfoProvider.managerId$
    ).pipe(
      distinctUntilChanged((prev, curr) => prev.every((previous, idx) => previous === curr[idx])),
      map(([username, role, app, version, customerName, managerId]) => ({
        dimension1: username,
        dimension2: role,
        dimension3: app,
        dimension4: version,
        dimension5: customerName,
        dimension6: `${managerId}`,
      }))
    );
  }
}
