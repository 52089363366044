import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService } from './feature-toggle.service';

/**
 * @whatItDoes Conditionally includes an HTML element if specified feature is enabled.
 *
 * @howToUse
 * ```
 *     <some-element *ampHasFeature="'showTour'">...</some-element>
 * ```
 */
@Directive({
  selector: '[ampHasFeature]',
})
export class HasFeatureDirective {
  private feature: string;
  private enabled: boolean;

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private featureToggleService: FeatureToggleService) {}

  @Input()
  set ampHasFeature(value: string) {
    this.feature = value;
    this.updateView();
  }

  private updateView(): void {
    if (this.feature) {
      this.enabled = this.featureToggleService.isOn(this.feature);
    }
    this.viewContainerRef.clear();
    if (this.enabled || !this.feature) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
