export enum PERMISSIONS {
  ADMIN_OP = 'ADMIN_OP',
  SYSTEM_ACTION = 'SYSTEM_ACTION',
  SERVICE_ACTION = 'SERVICE_ACTION',
  GET_USERS = 'GET_USERS',
  GET_DEVICE_GROUPS = 'GET_DEVICE_GROUPS',
  CREATE_DEVICE_GROUP = 'CREATE_DEVICE_GROUP',
  GET_DEVICES = 'GET_DEVICES',
  ADD_DEVICE = 'ADD_DEVICE',
  UPDATE_DEVICE = 'UPDATE_DEVICE',
  SEARCH_DEVICES = 'SEARCH_DEVICES',
  APPLY_CONF_ON_DEVICES = 'APPLY_CONF_ON_DEVICES',
  BACKUP_DEVICE_CONF = 'BACKUP_DEVICE_CONF',
  GET_MONITORING_VIEW = 'GET_MONITORING_VIEW',
  GET_OPERATIONS = 'GET_OPERATIONS',
  GET_PUBLICATIONS = 'GET_PUBLICATIONS',
  SETUP_PUBLICATION = 'SETUP_PUBLICATION',
  SETUP_PUBLICATION_RESOURCES = 'SETUP_PUBLICATION_RESOURCES',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  SETUP_DELIVERY = 'SETUP_DELIVERY',
  UPLOAD_LOGS = 'UPLOAD_LOGS',
  GET_UPLOAD_LOGS_STATUS = 'GET_UPLOAD_LOGS_STATUS',
  CREATE_MONITORING_VIEW = 'CREATE_MONITORING_VIEW',
}
