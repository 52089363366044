import {InjectionToken} from '@angular/core';
/**
 * below are environment properities that are used in libs modules outside of app.
 * since lazy load module with forRoot from router is not possible
 * https://stackoverflow.com/questions/59875936/angular-using-forroot-config-in-loadchildren-to-configure-a-lazy-loaded
 * these tokens are provided in app.module hency they are injected before really required(not well decoupled).
 */
export const GRAFANA_PATH = new InjectionToken<string>('grafanaPath');
export const LEGACY_PATH = new InjectionToken<string>('legacyPath');
export const PIP_PATH = new InjectionToken<string>('pipPath');
// todo this should be in the notifications module
export const AMP_WSS_PATH = new InjectionToken<string>('ampWssPath');
export const MONITORING_LIST_TEMPLATES = new InjectionToken<string>('monitoringListTemplates');  // Object of type Array<KeyValueDTO>


// todo why is this here ?
export * from './site-monitoring-config.tokens';
export * from './site-management-config.tokens';
