import { InjectionToken } from '@angular/core';

export interface ISiteManagementConfig {
  /** app base path for the module */
  moduleBasePath: string[];

  /**
   * Configure whether devices can be used in multiple boards.
   * Set to false if each device can only be used in a single board.
   * Set to true if each device can be used in multiple boards within the same site.
   */
  allowDevicesAcrossMultipleBoards: boolean;

  /**
   * Configure whether the maximum amounts of connections allowed for a device should
   * be limited to the amounts of the outputs.
   * E.g. Ng 4 can have 4 connections max
   */
  limitToDeviceOutputs: boolean;
}

export const DEFAULT_SITE_MANAGEMENT_CONFIG: ISiteManagementConfig = {
  moduleBasePath: ['app', 'site-management'],
  allowDevicesAcrossMultipleBoards: true,
  limitToDeviceOutputs: true,
};

export const SITE_MANAGEMENT_MODULE_CONFIG = new InjectionToken<ISiteManagementConfig>('SITE_MANAGEMENT_MODULE_CONFIG');
